<template>
  <div class="home">
    <LoginPage/>
  </div>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue'
export default {
  name: 'LoginView',
  components: {
    LoginPage
  }
}
</script>
