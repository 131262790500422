<template>
<div>
    <div id="cht-pop" :class="(!is_chat ? '' : 'open')">
            <div class="chat" ref='messageDisplay'>
               <div class="chat-header">
                  <h3>Chats</h3>
                  <span id="close-chat" @click.prevent="hideOfflineModal" style="cursor: pointer;"><i class="fas fa-times"></i></span>
               </div>
               <ChatMessagePage :chat_messages="chat_messages" :current_appointment_details="current_appointment_details" :user="user" :message_count="message_count" :current_appointment_therapist="current_appointment_therapist" :current_appointment_attendee="current_appointment_attendee"/>
            </div>
            <div class="chat__form">
               <form id="chat__form" v-on:submit.prevent>
                  <input id="text-message" type="text" placeholder="Type your message…" v-model="message" style="color: black !important;"  v-on:keyup.enter="sendMessage">
                  <button type="submit"><img src="../assets/images/send.svg" alt="" @click.prevent="sendMessage"></button>
               </form>
            </div>
         <div id="result"></div>
      </div>
</div>
</template>
<script>
import ChatMessagePage from '@/components/ChatMessagePage.vue'
export default {
  components:{
    ChatMessagePage
  },
  props:['is_chat','display_chat_color','chat_messages','current_appointment_details','user','message_count',"current_appointment_therapist","current_appointment_attendee"],
  watch: {
    message_count: function() {
//   console.log("Hello",this.message_count);
       this.scrollData();
    }
  },
  data() {
        return {
               background_color:"rgb(0 0 0 / 44%)",
               display_color:"block",
               display_no_color:"none",
              // chat_messages:[],
               message:"",
           };
  },
  mounted(){
//   console.log("Hello",this.display_call_initate_color);
  },
  methods:{
    hideOfflineModal(){
        console.log("hide modal",true);
         this.$emit('hideChatModal',{'closer':1,'load':0});
    },
    sendMessage(){
        if(!this.message){
                      this.$swal({icon: 'error', title:"Please enter message",background:'#cb1111',color:'#fff'});
        }else{
            let details = {};
            details.message = this.message;
            details.messageType = 1;
            this.$emit('sendMessage',details);
            this.message ="";
        }
    },
    onEntertingInput(e){
        e.preventDefault();
        console.log(e);
        return false;
    },
    scrollData(){
         let top = this.$refs.messageDisplay.scrollHeight - this.$refs.messageDisplay.clientHeight;
          if (typeof this.$refs.messageDisplay.scroll === 'function'){
           this.$refs.messageDisplay.scrollTop = (this.$refs.messageDisplay.scrollHeight - this.$refs.messageDisplay.clientHeight);
          } else {
            this.$refs.messageDisplay.scrollTop = top; 
          }
    }
  }
}
</script>
<style scoped>
.chat__message.chat__message-three p {
background: #2bbf763d;
}
.chat-img {
display: flex;
align-items: center;
margin-bottom: 10px;
flex-wrap: wrap;
}
.chat-img img {
max-width: 50px;
max-height: 50px;
min-width: 50px;
min-height: 50px;
border-radius: 100px;
margin-right: 10px;
}
.chat-img h3 {
font-size: 15px;
margin: 0 0 13px;
}
.chat-img h5 {
width: 100%;
font-size: 12px;
color: #9e9e9e;
padding-left: 61px;
margin-top: -20px;
}
.chat-img.order-chng {
justify-content: flex-end;
}
.chat-img.order-chng span img {
margin-left: 10px;
margin-right: 0;
}
.chat-img.order-chng h5 {
margin-top: -10px;
margin-bottom: 0;
text-align: right;
}
.chat-img.order-chng h3 {
text-align: right;
}
</style>