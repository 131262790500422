<template>
  <router-view @add-search-details="searchDetails" @search-cancelled = "searchCancelled" @show-add-card = "showAddCard" :load_data="load_data" :is_search="is_search" :current_appointment = "appointment" @appointment-status-update = "appointmentStatusUpdate" :current_appointment_status="current_appointment_status" :current_session_status="current_session_status" :current_appointment_details="current_appointment_details" :current_appointment_therapist="current_appointment_therapist" :current_appointment_attendee="current_appointment_attendee" :current_appointment_user ="current_appointment_user" :media_therapist_remote_stream ="therapist_media_stream" :media_partner_remote_stream="partner_media_stream" :media_stream="media_stream" :is_mute="is_mute" :is_support="is_support" :is_chat="is_chat" :is_blur="is_blur"  @change-audio-event ="changeAudioEvent" @change-video-event ="changeVideoEvent" @change-chat-event="changeChatEvent" @change-support-event ="changeSupportEvent"  @change-call-event="changeCallEvent" :current_user_video_stream="current_user_video_stream" :call_timer="call_timer"/>
  <AddPaymentMethod @card-saved ="cardSaved" :show_payment="show_payment"/>
  <MakePaymentMethod :show_make_card="show_make_card" :show_payment="show_payment" @money-added ="moneyAdded"/>
  <MessageModalPage @close-message-modal ="closeMessageModalFn" :message="modal_message" :show_message_modal="show_message_modal"/>
  <ExtendModalPage @close-message-modal ="closeExtendMessageModal" :message="modal_message" :show_message_modal="show_extend_message_modal" :extend_call_time="extend_call_time" :total_call_time="call_timer"/>
  <ExtendConfirmationModalPage @close-message-modal ="closeExtendConfirmationModal" :message="modal_message" :show_message_modal="show_extend_confirmation_modal" :extend_call_time="extend_call_time" :total_call_time="call_timer" :user="user" :selected_therapist_profile="selected_therapist_profile"/>
  <CallEndModalPage @close-message-modal ="closeCallEndModal" :message="modal_message" :show_message_modal="show_end_call_modal" :show_end_call_early_modal="show_end_call_early_modal" :extend_call_time="extend_call_time" :total_call_time="call_timer" :user="user" :current_appointment_therapist="current_appointment_therapist" :current_appointment_user="current_appointment_user" :current_appointment_attendee = "current_appointment_attendee" />
  <SessionDeclineModalPage  :decline_reasons="decline_reasons" :current_appointment_details="current_appointment_details" :display_decline_reason_color="display_decline_reason_color" @close-decline-end ="closeDeclineEnd"/>
  <RiseAnIssue :show_reason_modal="show_reason_modal" :search_modal_details="current_appointment_details" :display_reason_color="display_reason_color" @close-reason="closeReason"/>
  <ChatPage :is_chat="is_chat" :display_chat_color="display_chat_color" @hide-chat-modal="hideChatModal" @send-message="sendMessage" :chat_messages="chat_messages" :current_appointment_details="current_appointment_details" :user="current_appointment_user" :current_appointment_therapist="current_appointment_therapist" :current_appointment_attendee="current_appointment_attendee" :message_count="message_count"/>
</template>
<script>
  import { Peer } from "peerjs";
  import { io } from 'socket.io-client';
  import Auth from '@/resources/Auth.js';
  import Constant from '@/resources/Constant.js';
  import ChatPage from '@/components/ChatPage.vue'
  import RiseAnIssue from '@/components/RiseAnIssue.vue'
  import ExtendModalPage  from "@/components/ExtendModalPage.vue";
  import AddPaymentMethod  from "@/components/AddPaymentMethod.vue";
  import MessageModalPage  from "@/components/MessageModalPage.vue";
  import MakePaymentMethod from "@/components/MakePaymentMethod.vue";
  import CallEndModalPage  from "@/components/CallEndModalPage.vue";
  import SessionDeclineModalPage  from "@/components/SessionDeclineModalPage.vue";
  import ExtendConfirmationModalPage  from "@/components/ExtendConfirmationModalPage.vue";

  export default {
    components:{
     AddPaymentMethod,CallEndModalPage,MessageModalPage,MakePaymentMethod,RiseAnIssue,ChatPage,ExtendModalPage,ExtendConfirmationModalPage,SessionDeclineModalPage
    },
    methods:{
        showAddCard(type){
           this.series = this.series + 1;
           if(type == 'add_money_to_wallet'){
            this.show_make_card = true;
            this.show_payment = "add_money_to_wallet"+this.series;
           }else{
            this.show_payment = "add_card";
           }
        },
        changeAudioEvent(type){
            console.log(type);
               if(type == "mute"){
                this.is_mute = true;
                if(this.media_stream)
                this.media_stream.getAudioTracks()[0].enabled = false;
               }
              if(type == "unmute"){
                this.is_mute = false;
                if(this.media_stream)
                this.media_stream.getAudioTracks()[0].enabled = true;
               }
        },
        changeCallEvent(event){
            console.log("call end",event);
            if(this.display_decline_reason_color == 'none'){
             this.display_decline_reason_color = 'block';
            }else{
             this.display_decline_reason_color = 'none';
            }
        },
        closeDeclineEnd(info){
          if(info.closer == 1){
              this.show_message_modal = false;
              this.current_appointment_status = null;
              this.current_session_status = null;
              this.extend_call_time = 0;
              this.current_call_extend_status = 0;
              this.extend_call_ask_status = 0;
              this.current_appointment_details = null;
              this.call_timer = 0;
              this.message_count = 0;
              this.current_appointment_therapist = null;
              this.selected_therapist_profile = null;
              this.current_appointment_attendee = null;
              this.current_appointment_user = null;
              this.appointment = null;
              this.search_data = null;
              this.modal_message = null;
              this.show_extend_message_modal = null;
              this.show_extend_confirmation_modal = null;
              this.show_end_call_modal = null;
              this.show_end_call_early_modal = null;
              this.display_decline_reason_color = "none";
              this.display_reason_color = "none";
              this.display_chat_color = "none";
              this.show_reason_modal = "none";

              //===========================//
              //===========================//
              if(this.media_stream){
                this.media_stream.getTracks().forEach((track) => {
                  track.stop();
                });
              }
              if(this.$mediaStream){
                this.$mediaStream.getTracks().forEach((track) => {
                  track.stop();
                });
              }
              this.partner_media_stream = null;
              this.therapist_media_stream = null;
              this.media_stream = null;
              this.$mediaStream = null;
              this.$mediaTherapistRemoteStream = null;
              this.$mediaPartnerRemoteStream = null;
              this.partner_media_stream = null;
              this.$router.push({'name':'home'});
          }else{
              this.display_decline_reason_color = "none";
          }
        },
        changeVideoEvent(type){
          if(this.media_stream && this.media_stream.getVideoTracks().length){
               if(type == "blur"){
                 this.is_blur = true;
                 if(this.media_stream){
                    this.blurVideoCall(this.is_blur,this.current_appointment_therapist.id);
                    if(this.current_appointment_attendee)
                    this.blurVideoCall(this.is_blur,this.current_appointment_attendee.id);
                    if(this.media_stream.getVideoTracks().length)
                    this.media_stream.getVideoTracks()[0].enabled = false;
                 }
               }
              if(type == "unblur"){
                 this.is_blur = false;
                 if(this.media_stream){
                   this.media_stream.blur = false;
                   this.$mediaStream.blur = false;
                   this.blurVideoCall(this.is_blur,this.current_appointment_therapist.id);
                   if(this.current_appointment_attendee)
                   this.blurVideoCall(this.is_blur,this.current_appointment_attendee.id);
                    if(this.media_stream.getVideoTracks().length)
                    this.media_stream.getVideoTracks()[0].enabled = true;
                 }
               }
          }
        },
        changeChatEvent(type){
              if(type == "show"){
                this.is_chat = true;
                this.message_count = 0;
                this.display_chat_color = "block";
              }
              if(type == "hide"){
                this.is_chat = false;
                this.message_count = 0;
                this.display_chat_color = "none";

              }
        },
        changeSupportEvent(type){
            if(type == "show"){
              this.is_support = true;
              this.show_reason_modal = true;
              this.display_reason_color = "block";
            }
            if(type == "hide"){
              this.is_support = false;
              this.show_reason_modal = false;
              this.display_reason_color = "none";
            }
            console.log("show support options",type);
        },
        sendMessage(data){
          let today = new Date();
          let post_data = data;
          post_data.sender_name = this.current_appointment_user.name;
          post_data.senderId = this.current_appointment_user.id;
         
          if(this.current_appointment_therapist && (this.current_appointment_therapist.id !== undefined)){
            post_data.receiverId = this.current_appointment_therapist.id;
            post_data.image_url  = this.current_appointment_user ? this.current_appointment_user.profile_image : null;
            let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
            let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            let dateTime = date +''+ time;
            post_data.request_id = dateTime;
            if(this && this.$socket){
             this.$socket.emit('sendMessage',post_data);
            }
          }
          if(this.current_appointment_user && (this.current_appointment_user !== undefined)){
            post_data.receiverId = this.current_appointment_user.id;
            post_data.image_url = this.current_appointment_user.profile_image;
            let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
            let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            let dateTime = date +''+ time;
            post_data.request_id = dateTime;
            if(this && this.$socket){
             this.$socket.emit('sendMessage',post_data);
            }
          } 
          if(this.current_appointment_attendee && (this.current_appointment_attendee !== undefined)){
            if(this.current_appointment_details.attendee){
              post_data.receiverId = this.current_appointment_attendee.id;
              post_data.image_url = null;
              let date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();
              let time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
              let dateTime = date +''+ time;
              post_data.request_id = dateTime;
              if(this && this.$socket){
               this.$socket.emit('sendMessage',post_data);
              }
            }
          }
        },
        closeExtendMessageModal(type){
          if(type == 'no'){
             this.show_extend_message_modal = false;
             this.show_extend_confirmation_modal = false;
             let status = 0;
             this.updateExtendedDetails(this.appointment.id,'user_ask_status',0);
             this.askUserForExtendSession(status);
          }
          if(type == 'yes'){
             let status = 1;
             this.show_extend_message_modal = false;
             this.show_extend_confirmation_modal = true;
             this.updateExtendedDetails(this.appointment.id,'user_ask_status',1);
             this.askUserForExtendSession(status);
          }
        },
        closeCallEndModal(type){
          if(type == 'no'){
             this.show_end_call_modal = false;
          }
          if(type == 'yes'){
             this.show_end_call_modal = false;
          }
        },
        closeExtendConfirmationModal(type){
          if(type == 'no'){
             this.show_extend_confirmation_modal = false;
             let status = 0;
             this.updateExtendedDetails(this.appointment.id,'user_ask_status',0);
             this.askUserForExtendSession(status);
          }
          if(type == 'yes'){
             //let status = 1;
             this.show_extend_confirmation_modal = false;
           /*  this.updateExtendedDetails(this.appointment.id,'user_ask_status',1);
             this.askUserForExtendSession(status);*/
          }
        },
        closeMessageModalFn(){
           this.show_message_modal = false;
        },
        closeReason(info){
          if(info.closer == 1){
              this.is_support = false;
              this.display_reason_color = "none";
          }
        },
        searchCancelled(type){
          this.is_search = type ? false : true;
          if(this.$socket){
            let post_data = {};
            if(this.appointment){
              post_data.appointment_id = this.appointment.id;
            }
            if(this.user){
              post_data.user_id = this.user.id;
            }
             this.$socket.emit("cancelActionByUser",post_data);
             this.show_message_modal = false;
             this.appointment  = null;
             this.search_data = null;
          }
        },
        cardSaved(type){
          if(this.show_payment != "add_card"){
             this.series = this.series + 1;
             this.show_make_card = true;
             this.show_payment = "add_money_to_wallet"+this.series;
          }else{
            if(type){
              this.load_data++;
            }
          }
        },
        moneyAdded(type){
          if(this.show_payment != "add_card"){
             this.load_data++;
          }else{
            if(type){
              this.load_data++;
            }
          }
        },
        searchDetails(search_data){
           if(search_data.appointment){
             this.appointment = search_data.appointment;
             this.search_data = search_data;
             this.is_search = true;
           }
        },
        hideChatModal(info){
           if(info.closer == 1){
              this.message_count = 0;
              this.is_chat = false;
              this.display_chat_color = "none";
          }
        },
        blurVideoCall(video_state,user_id){
          if(this.$socket){
           
            let post_data = {};
                post_data.video = video_state;
                post_data.user_id = user_id;
                post_data.provider_type = (this.current_appointment_attendee && (this.current_appointment_attendee.id == this.user.id)) ? 'PARTNER' : 'USER';
                console.log("dsdsad",post_data);
               this.$socket.emit("userBlurAction",post_data);
               this.appointmentVideoStatusUpdate(video_state);
          }
        },
        getSelectedTherapistProfile(){
          let get_therapist_profile = Constant.getbaseurl()+'/therapist/profile';
           this.axios.get(get_therapist_profile,{ params: {'appointment_id':this.current_appointment_details.appointment_id,'therapist_id':this.current_appointment_therapist.id}})
           .then(({data}) => {
                  let response_data = data.data;
                  if(response_data !== undefined){
                   this.selected_therapist_profile = response_data.therapist_detail;
                  }
           })
           .catch((error) => {
               if(error.response.status == 401){
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });
        },
        getAgreedTherapist(appointment,therapist = null){
                let user_agreed_therapist = Constant.getbaseurl()+'/appointment/list_of_agreed_therapist';
                this.axios.post(user_agreed_therapist,{'appointment_id':appointment})
                  .then(({data}) => {
                      let response_data = data.data;
                      this.is_search = false;
                      if(response_data && response_data.agreed_therapists !== undefined && response_data.agreed_therapists.length){
                        if(therapist){
                          this.$router.push({'name':'found_therapist','params':{'appointment_id':this.appointment.id}});
                          let post_data = {};
                          if(this.appointment){
                            post_data.appointment_id = this.appointment.id;
                          }
                          if(this.user){
                            post_data.user_id = this.user.id;
                          }
                          post_data.therapist_ids = therapist;
                          this.$socket.emit("requestEnableTimer",post_data);
                        }
                      }else{
                        this.modal_message = "Sorry "+this.user.name+", No Therapist accepted your request.";
                        this.show_message_modal = true;
                        this.appointment = null;
                        this.search_data = null;
                      }
                  })
                  .catch((error) => {
                      console.log("error",error);
                      if(error.response.status == 401){
                           this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                           Auth.logout();
                           this.$router.push('login');
                       }else{
                           this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                       }
                  });

        },
        connectSocket(){
          try{
            this.user = window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null;
            if(this.user){
              console.log("user status",this.user);
                this.$socket = io(process.env.VUE_APP_SOCKET_URL+""+this.user.id, {
                  transports:['websocket'],
                  cors: {
                    origin: "https://user.naqqe.app"
                  }
                });
                this.$socket.on("connect", () => {
                     // console.log("socket_id connected",this.$socket.id); // x8WIv7-mJelg7on_ALbx
                      this.$socket.on("disconnect", (error) => {
                      console.log("socket id disconnect error",error); // x8WIv7-mJelg7on_ALbx
                      });
                });
                this.$socket.on('requestActionByTherapist',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id)){
                    if(payload.statuscode == 501){
                      if(this.appointment && payload.therapist_ids){
                        this.getAgreedTherapist(this.appointment.id,payload.therapist_ids);
                      }else{
                      this.is_search   = false;
                      this.modal_message = "Sorry "+this.user.name+", No Therapist accepted your request.";
                      this.show_message_modal = true;
                      this.appointment = null;
                      this.current_appointment_status = null;
                      this.current_session_status = null;
                      this.appointment_expired = null;
                      this.extend_call_time = 0;
                      this.extend_call_ask_status = 0;
                      this.current_appointment_details = null;
                      this.call_timer = 0;
                      this.current_appointment_therapist = null;
                      this.selected_therapist_profile = null;
                      this.current_appointment_attendee = null;
                      this.current_appointment_user = null;
                      this.search_data = null;
                      this.show_extend_message_modal = null;
                      this.show_extend_confirmation_modal = null;
                      this.show_end_call_modal = null;
                      this.show_end_call_early_modal = null;
                      }
                    }
                  }
                });
                this.$socket.on('abortTherapistRequest',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id)){
                    if(payload.statuscode == 501){
                      this.is_search   = false;
                      if(this.appointment && payload.therapist_ids){
                        this.getAgreedTherapist(this.appointment.id,payload.therapist_ids);
                      }else{
                      this.modal_message = "Sorry "+this.user.name+", No Therapist accepted your request.";
                      this.show_message_modal = true;
                      this.appointment = null;
                      this.current_session_status = null;
                      this.current_appointment_status = null;
                      this.extend_call_time = 0;
                      this.extend_call_ask_status = 0;
                      this.current_appointment_details = null;
                      this.call_timer = 0;
                      this.current_appointment_therapist = null;
                       this.selected_therapist_profile = null;
                      this.current_appointment_attendee = null;
                      this.current_appointment_user = null;
                      this.appointment_expired = null;
                      this.search_data = null;
                      this.show_extend_message_modal = null;
                      this.show_extend_confirmation_modal = null;
                      this.show_end_call_modal = null;
                      this.show_end_call_early_modal = null;
                      }
                    }
                  }
                });
                this.$socket.on('abortUserRequest',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id)){
                    if(payload.statuscode == 501){
                      this.is_search   = false;
                      this.modal_message = null;
                      this.show_message_modal = false;
                      this.appointment = null;
                      this.current_appointment_status = null;
                      this.extend_call_time = 0;
                      this.extend_call_ask_status = 0;
                      this.current_appointment_details = null;
                      this.call_timer = 0;
                      this.current_appointment_therapist = null;
                      this.selected_therapist_profile = null;
                      this.current_appointment_attendee = null;
                      this.current_appointment_user = null;
                      this.current_session_status = null;
                      this.appointment_expired = null;
                      this.search_data = null;
                      this.show_extend_message_modal = null;
                      this.show_extend_confirmation_modal = null;
                      this.show_end_call_modal = null;
                      this.show_end_call_early_modal = null;
                    }
                  }
                });
                this.$socket.on('userTherapistRequestPaymentTimeOut',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id) && (this.user.id == payload.user_id)){
                    if(payload.statuscode == 506){
                      this.appointment_expired = "PAYMENT_TIMEOUT";
                      if(!this.current_appointment_status || (this.current_appointment_status == 'payment_error' || this.current_appointment_status == 'payment_status_error' || this.current_appointment_status == 'payment_started'))
                          this.modal_message = "Sorry "+this.user.name+", You takes to much time to make payment.";
                          this.show_message_modal = true;
                          this.current_appointment_status = null;
                          this.current_session_status = null;
                          this.extend_call_time = 0;
                          this.extend_call_ask_status = 0;
                          this.current_appointment_details = null;
                          this.call_timer = 0;
                          this.current_appointment_therapist = null;
                          this.selected_therapist_profile = null;
                          this.current_appointment_attendee = null;
                          this.current_appointment_user = null;
                          this.appointment = null;
                          this.search_data = null;
                          this.show_extend_message_modal = null;
                          this.show_extend_confirmation_modal = null;
                          this.show_end_call_modal = null;
                          this.show_end_call_early_modal = null;
                          this.$router.push({'name':'home'});
                      }
                  }
                });
                this.$socket.on('callEndedByTherapist',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id)){
                      this.modal_message = this.current_appointment_therapist.name+" has ended the session.";
                      this.show_message_modal = true;
                      this.current_appointment_status = null;
                      this.current_session_status = null;
                      this.extend_call_time = 0;
                      this.extend_call_ask_status = 0;
                      this.current_appointment_details = null;
                      this.call_timer = 0;
                      this.current_appointment_therapist = null;
                      this.selected_therapist_profile = null;
                      this.current_appointment_attendee = null;
                      this.current_appointment_user = null;
                      this.appointment = null;
                      this.search_data = null;
                      this.show_extend_message_modal = null;
                      this.show_extend_confirmation_modal = null;
                      this.show_end_call_modal = true;
                      this.show_end_call_early_modal = true;
                      if(this.media_stream){
                        this.media_stream.getTracks().forEach((track) => {
                          track.stop();
                        });
                      }
                      if(this.$mediaStream){
                        this.$mediaStream.getTracks().forEach((track) => {
                          track.stop();
                        });
                      }
                      this.partner_media_stream = null;
                      this.therapist_media_stream = null;
                      this.media_stream = null;
                      this.$mediaStream = null;
                      this.$mediaTherapistRemoteStream = null;
                      this.$mediaPartnerRemoteStream = null;
                      this.partner_media_stream = null;
                      this.$router.push({'name':'home'});
                  }
                });
                this.$socket.on("userTherapistCallTimeOut",(payload) => {

                     if(this.appointment && (this.appointment.id == payload.appointment_id)){
                          this.modal_message = this.current_appointment_therapist.name+" has ended the session.";
                          this.show_message_modal = false;
                          this.current_appointment_status = null;
                          this.current_session_status = null;
                          this.extend_call_time = 0;
                          this.extend_call_ask_status = 0;
                          this.current_appointment_details = null;
                          this.call_timer = 0;
                          this.current_appointment_therapist = null;
                          this.selected_therapist_profile = null;
                          this.current_appointment_attendee = null;
                          this.current_appointment_user = null;
                          this.appointment = null;
                          this.search_data = null;
                          this.show_extend_message_modal = null;
                          this.show_extend_confirmation_modal = null;
                          this.show_end_call_modal = true;
                          this.show_end_call_early_modal = null;
                          if(this.media_stream){
                            this.media_stream.getTracks().forEach((track) => {
                              track.stop();
                            });
                          }
                          if(this.$mediaStream){
                            this.$mediaStream.getTracks().forEach((track) => {
                              track.stop();
                            });
                          }
                          this.partner_media_stream = null;
                          this.therapist_media_stream = null;
                          this.media_stream = null;
                          this.$mediaStream = null;
                          this.$mediaTherapistRemoteStream = null;
                          this.$mediaPartnerRemoteStream = null;
                          this.partner_media_stream = null;
                          this.$router.push({'name':'home'});
                      }
                });

                this.$socket.on('requestEnableTimer',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id) && (this.user.id == payload.user_id)){
                     let timer_interval = parseInt(payload.timer_interval);
                     this.getAcceptTherapistTimer(timer_interval);
                  }
                });
                this.$socket.on('callInitiated',(payload) => {
                  console.log('call current_appointment_status',this.current_appointment_status);
                  if(this.appointment && (this.appointment.id == payload.videoDetails.appointment_id) && (this.user.id == payload.user_id)){
                      if(this.current_appointment_status && (this.current_appointment_status == 'payment_done' || this.current_appointment_status == 'payment_started')){
                          if (payload.videoDetails.sessionStarted) {
                              this.current_session_status = "CALL_STARTED";
                              this.getAudioVideoCallPermissions(null,"connect_peer");
                          } else {
                              this.current_session_status = "CALL_NOT_STARTED";
                              this.startCallByUser();
                          }
                          this.checkOngoingCall();
                          this.$router.push({'name':'call_page','params':{'appointment_id':this.appointment.id}});
                      }
                  }else if(payload.videoDetails.appointment_id){
                     
                       this.getSessionDetails(payload.videoDetails.appointment_id);
                  }
                });
                this.$socket.on('emitPeerJsToken', (payload) => {
                  if(this.$peerInstance){
                    this.callRemoteUser(payload.peer_id, payload.sendername, payload.provider_type);
                  }else{
                      if(this.current_session_status != 'CALL_STARTED' && this.current_session_status != 'CALL_NOT_STARTED'){
                       this.getSessionDetails(payload.appointment_id);
                      }
                  }
                });
                this.$socket.on('checkPingUser',(payload) => {
                  console.log("user is pinging out",payload);
                });
                this.$socket.on('readMessage',(payload) => {
                  if(payload.senderId != this.user.id){
                    this.message_count++;
                  }
                  this.chat_messages.push(payload);
                  console.log("user is readMessage out",payload);
                });
                this.$socket.on('sendMessage',(payload) => {
                  console.log("user is sendMessage out",payload);
                });
                this.$socket.on("update_ongoing_appointment_session",(payload) => {
                   let ongoing_call_details = payload;
                    if(payload.details !== undefined){
                    if(ongoing_call_details && ongoing_call_details.details.total_call_time){
                      this.call_timer = ongoing_call_details.details.call_timer - ongoing_call_details.details.total_call_time;
                      this.pause_call_timer = false;
                    }
                    if(!this.client_call_interval_timeout){
                      if(parseInt(this.call_timer) > 0){
                        let appointment_details = (ongoing_call_details.appointment !== undefined ? ongoing_call_details.appointment : null);
                          if(appointment_details && (appointment_details[0].extend_request_status == 1) && (appointment_details[0].extended_request_time) && (!appointment_details[0].is_extended)){
                             this.countDownCallTimer(true);
                          }else{
                             this.countDownCallTimer();
                          }
                      }
                    }
                  }else{
                      clearTimeout(this.client_call_interval_timeout);
                      this.client_call_interval_timeout = null;
                  }
                });
                this.$socket.on('ExtendCallRequestByTherapist',(payload) => {
                  if(this.appointment && (this.appointment.id == payload.appointment_id) && (this.user.id == payload.receiver_id)){
                    this.modal_message =  (this.current_appointment_therapist ? this.current_appointment_therapist.name : '')+" is asking for extending session."
                    this.show_extend_message_modal = "block";
                    this.show_extend_confirmation_modal = false;
                    this.show_message_modal = false;
                    this.pause_call_time = false;
                    this.pause_call_timer = this.call_timer;
                    this.extend_call_ask_status = 1;
                  }
                  console.log("ExtendCallRequestByTherapist",payload);
                });
                this.$socket.on('cancelExtendedSessionRequest',(payload) => {
                   this.user = window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null;
                    if(payload && this.user && (payload.appointment_id == this.appointment.id) && (this.user.id == payload.receiver_id)){
                          this.modal_message = "Due to delay in payment, we are decling the extend request.Please continue with session";
                          this.is_active_user_wait_modal = 1;
                          this.extend_call_status = 0;
                          this.extend_call_ask_status = 1;
                          this.show_message_modal = true;
                          this.pause_call_time = false;
                          //this.updateExtendedDetails(this.is_active_appointment_id,'appointment_extend_payment_status',3);
                          this.checkOnlineCallTimer();
                      }
                });
              
            }
          }
          catch(err) {
            console.log("err in socket",err);
          }
      },
      updateExtendedDetails(appointment_id,type = "user_ask_status",status = 1){
         let save_extended_session = Constant.getbaseurl()+'/therapist/save_extended_session_status';
         let post_data = null;
         if(type == 'user_ask_status'){
           post_data = {'user_ask_status':status,'appointment_id':appointment_id};
         }
         this.axios.post(save_extended_session,post_data)
         .then(() => {
              this.extend_call_ask_status = 1;
         })
         .catch((error) => {
             if(error.response.status == 401){
                 Auth.logout();
                 this.$router.push('login');
             }else{
                console.log("error",error);
             }
         });
      },
      getExtendSessionDetails(appointment_id){
        let check_extended_session = Constant.getbaseurl()+'/therapist/check_extended_session_status';
         this.axios.get(check_extended_session,{ params: {'appointment_id':appointment_id}})
         .then(({data}) => {
              let response_data = data.data;
                this.extend_call_ask_status = 1;
                this.current_call_extend_status = response_data.is_extended;
                this.extend_call_status = response_data.is_extended;
         })
         .catch((error) => {
             if(error.response.status == 401){
                 Auth.logout();
                 this.$router.push('login');
             }else{
                 //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
             }
         });
      },
      getAcceptTherapistTimer(timer = null){
        if(this.accept_therapist_timeout){
          clearTimeout(this.accept_therapist_timeout);
        }
        if(timer > 1){
          this.accept_therapist_timeout = setTimeout(() => {
              timer = timer - 1;
              this.getAcceptTherapistTimer(timer);
          }, 1000);
        }else{
          if (this.appointment && this.current_appointment_status && (this.appointment_expired == 'PAYMENT_TIMEOUT') && (!this.current_appointment_status || (this.current_appointment_status == 'payment_error' || this.current_appointment_status == 'payment_status_error'))) {
                  this.modal_message = "Sorry "+this.user.name+", You takes to much time to make payment.";
                  this.show_message_modal = true;
                  this.current_appointment_status = null;
                  this.current_session_status = null;
                  this.extend_call_time = 0;
                  this.extend_call_ask_status = 0;
                  this.current_appointment_details = null;
                  this.call_timer = 0;
                  this.current_call_extend_status = 0;
                  this.current_appointment_therapist = null;
                  this.selected_therapist_profile = null;
                  this.current_appointment_attendee = null;
                  this.current_appointment_user = null;
                  this.appointment = null;
                  this.search_data = null;
                  this.show_extend_message_modal = null;
                  this.show_extend_confirmation_modal = null;
                  this.show_end_call_modal = null;
                  this.show_end_call_early_modal = null;
                  this.$router.push({'name':'home'});
            }
        }
      },
      getAudioVideoCallPermissions(start_call = null,connect_peer = null){
            if(this.media_stream){
              this.media_stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            if(this.$mediaStream){
              this.$mediaStream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            navigator.mediaDevices.getUserMedia({video:true, audio: true }).then((media) => {
              this.media_stream = media;
              this.$mediaStream = media;
              this.current_user_video_stream = true;
              if(start_call){
               this.startCallByUser(false);
              }
              if(connect_peer){
               this.connectPeerjs();
              }
            }).catch((err) => {
              console.log(err,"error data");
               this.getAudioCallPermissions(start_call,connect_peer);
            });
      },
      getAudioCallPermissions(start_call = null,connect_peer = null){
            if(this.media_stream){
              this.media_stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            if(this.$mediaStream){
              this.$mediaStream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            navigator.mediaDevices.getUserMedia({video:false, audio: true }).then((media) => {
              this.media_stream = media;
              this.$mediaStream = media;
              this.current_user_video_stream = false;
              if(start_call){
               this.startCallByUser(false);
              }
              if(connect_peer){
               this.connectPeerjs();
              }
              if(this.current_appointment_therapist)
              this.blurVideoCall(true,this.current_appointment_therapist.id);
              if(this.current_appointment_attendee)
              this.blurVideoCall(true,this.current_appointment_attendee.id);
            }).catch((err) => {
              console.log(err,"checkAudioVideoCallPermissions");
               this.checkAudioVideoCallPermissions(start_call,connect_peer);
            });
      },
      checkAudioVideoCallPermissions(start_call = null,connect_peer = null){

          navigator.permissions.query({name: 'microphone'})
           .then((permissionObj) => {
                navigator.permissions.query({name: 'camera'})
                 .then((permissionCamObj) => {
                      if(permissionObj.state == 'denied' && permissionCamObj.state == 'denied'){
                       this.$swal({icon: 'error', title: "Please give audio and video permissions to continue call",background:'#cb1111',color:'#fff'});
                      }else if(permissionObj.state == 'denied'){
                       this.$swal({icon: 'error', title: "Please give audio permissions to continue call",background:'#cb1111',color:'#fff'});
                      }else if(permissionCamObj.state == 'denied'){
                        this.getAudioCallPermissions(start_call,connect_peer);
                      }
                     // console.log(permissionCamObj,permissionObj, start_call,connect_peer);
                 })
                 .catch((error) => {
                      console.log('Got error :', error);
                 })
               //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
           })
           .catch((error) => {
                console.log('Got error :', error);
           })
      },
      startCallByUser(ask_permission = true){
        if(ask_permission){
          this.getAudioVideoCallPermissions('start_call');
        }else{
         this.getSessionDetails();
        }
      },
      countDownCallTimer(pause_time = false){
            
            if (this.call_timer >= 0) {
              if(this.client_call_interval_timeout){
                  clearTimeout(this.client_call_interval_timeout);
                  this.client_call_interval_timeout = null;
              }
              this.client_call_interval_timeout = setTimeout(() => {
                    if((this.call_timer < this.ask_extend_timer) && (!this.extend_call_status) && (this.appointment)){
                        if(!this.extend_call_ask_status)
                        this.getExtendSessionDetails(this.appointment.id);
                    }
                    if(pause_time){
                      this.call_timer -= 1;
                    }
                    if(this.call_timer){
                      this.checkOnlineCallTimer();
                      this.countDownCallTimer();
                    }
              },1000);

            }else{
                  clearTimeout(this.client_call_interval_timeout);
                  this.client_call_interval_timeout = null;
            }
      },
      getCallDeclineReasons(){
          let ongoing_call_decline_reasons = Constant.getbaseurl()+'/decline/reasons';
           this.axios.get(ongoing_call_decline_reasons,{ params: {flow_type:"CALL_END",role: 'THERAPIST'}})
           .then(({data}) => {
                let response_data = data.data;
                this.decline_reasons = response_data;
           })
           .catch((error) => {
               if(error.response.status == 401){
                   Auth.logout();
                   this.$router.push('login');
               }
           });
      },
      checkOnlineCallTimer(){
       let online_data = {};
        if(this.appointment){
          online_data.appointment_id = this.appointment.id;
          online_data.user_id = this.user ? this.user.id : null;
          online_data.user_type = 'USER';
          this.$socket.emit('check_ongoing_appointment_session',online_data)
        }
      },
      askUserForExtendSession(status){
            let ask_extend_appointment = Constant.getbaseurl()+'/therapist/get-user-response-extended-session';
            let post_data = {
             'status':parseInt(status),
             'appointment_id':this.appointment.id,
             'therapist_id':this.current_appointment_therapist ? this.current_appointment_therapist.id : null,
             'user_id':this.current_appointment_user ? this.current_appointment_user.id : null,
             'partner_id':this.current_appointment_attendee ? this.current_appointment_attendee.id : null,
            };
            this.axios.post(ask_extend_appointment,post_data)
            .then((/*{data}*/) => {
               console.log(post_data,"post data");
            })
            .catch((error) => {
               if(error.response.status !== undefined &&(error.response.status == 401)){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
            });
      },
      syncOngoingCallTimer(){
           let ongoing_call = Constant.getbaseurl()+'/therapist/check_ongoing_call';
           this.axios.post(ongoing_call,{'modifiedNew':1})
           .then(({data}) => {
                let response_data = data.data;
                this.ongoing_call_details = response_data;
                if(this.ongoing_call_details && this.ongoing_call_details.total_call_seconds){
                  this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                  this.ask_extend_timer = this.ongoing_call_details.appointment_detail.extend_session_ask_request_timer;
                  this.pause_call_timer = false;
                }
                if(this.client_call_interval_timeout){
                 clearTimeout(this.client_call_interval_timeout);
                }
                this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                if(parseInt(this.call_timer) > 0){
                  this.countDownCallTimer();
                }
           })
           .catch((error) => {
               if(error.response.status == 401){
                   Auth.logout();
                   this.$router.push('login');
               }
           });
      },
      checkOngoingCall(enable_timer = null){
              let ongoing_call = Constant.getbaseurl()+'/therapist/check_ongoing_call';
               this.axios.post(ongoing_call,{'modifiedNew':1})
               .then(({data}) => {
                    let response_data = data.data;
                    this.ongoing_call_details = response_data;
                    if(this.ongoing_call_details && (this.ongoing_call_details.total_call_seconds <= this.ongoing_call_details.total_call_time)){
                        this.ask_extend_timer = this.ongoing_call_details.appointment_detail.extend_session_ask_request_timer;
                        if(enable_timer){
                          let enable_call_data = {};
                          enable_call_data.customer_id = this.current_appointment_details.user ? this.current_appointment_details.user.id : null;
                          enable_call_data.user_id = this.current_appointment_details.user ? this.current_appointment_details.user.id : null;
                          enable_call_data.therapist_id = this.current_appointment_details.therapist.id;
                          enable_call_data.appointment_id = this.current_appointment_details.appointment_id;
                          enable_call_data.attendee_id = this.current_appointment_details.attendee ? this.current_appointment_details.attendee.user_id:null;
                          enable_call_data.is_extended = 0;
                          this.$socket.emit('enableCallTimer',enable_call_data);
                        }
                        if(this.client_call_interval_timeout){
                         clearTimeout(this.client_call_interval_timeout);
                        }
                        this.call_timer = this.ongoing_call_details.total_call_time - this.ongoing_call_details.total_call_seconds;
                        this.getCallDeclineReasons();
                        this.countDownCallTimer();
                    }
               })
               .catch((error) => {
                   console.log(error);
                   if(error.response.status == 401){
                       //this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                       Auth.logout();
                       this.$router.push('login');
                   }else{
                       //this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
                   }
               });
      },
      getSessionDetails(appointment_id = null){
        let session_user_details = Constant.getbaseurl()+'/get-all-session-users-details';
        let current_appointment_id = appointment_id ?? this.appointment.id;
        this.axios.post(session_user_details,{'appointment_id':current_appointment_id})
          .then(({data}) => {
              let response_data = data.data;
              if(appointment_id){
                this.appointment = response_data;
              }
              if(this.appointment){
                this.current_appointment_details = response_data.accepted_appointment;
                this.user_media_stream_status = parseInt(this.current_appointment_details.is_video_enabled_by_user);
                this.partner_media_stream_status = parseInt(this.current_appointment_details.is_video_enabled_by_attendee);
                this.extend_call_time = this.current_appointment_details.extend_call_timer;
                this.current_call_extend_status = this.current_appointment_details.is_extended;
                if((this.appointment.status <= 2) && (this.current_appointment_details.actual_start_time && !this.current_appointment_details.actual_end_time && (this.current_appointment_details.is_accepted))){

                  this.current_appointment_therapist = response_data.therapist;
                  this.current_appointment_attendee  = response_data.attendee;
                  this.current_appointment_user      = response_data.user;
                  this.current_appointment_status    = "payment_done";
                  this.current_session_status        = "CALL_STARTED";
                  this.checkOnlineCallTimer();
                  this.getAudioVideoCallPermissions(null,'connect_peer');
                
                }else if((this.appointment.status <= 2) && (this.current_appointment_details.is_accepted)){

                  this.current_appointment_therapist = response_data.therapist;
                  this.current_appointment_attendee  = response_data.attendee;
                  this.current_appointment_user      = response_data.user;
                  this.current_appointment_status    = "payment_done";
                  this.current_session_status        = "CALL_STARTED";
                  this.checkOnlineCallTimer();
                  this.getAudioVideoCallPermissions(null,'connect_peer');

                }else{

                  this.current_appointment_status    = null;
                  this.current_session_status        = null;
                  this.current_appointment_details   = null;
                  this.current_appointment_therapist = null;
                  this.selected_therapist_profile    = null;
                  this.current_appointment_attendee  = null;
                  this.current_appointment_user      = null;
                  this.appointment = null;
                  this.search_data = null;
                  this.$router.push({'name':'home'});
                }
              }
              if(this.current_appointment_therapist && this.current_appointment_details){
                this.getSelectedTherapistProfile();
              }
              console.log("live appointment data",response_data);
          })
          .catch((error) => {
              if(error.response.status == 401){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
          });
      },
      connectPeerjs(){
          let peer_user_id = this.generateUUID();
          this.$peerInstance = new Peer(peer_user_id,{
            host: process.env.VUE_APP_PEER_SERVER,
            path: '/',
            debug:1,
            secure:true,
            config: {
                "iceServers": [
                  {
                    'urls': process.env.VUE_APP_TURN,
                    'username': process.env.VUE_APP_PEER_USER,
                    "iceTransportPolicy": 'relay',
                    'credential':process.env.VUE_APP_PEER_PWD,
                  },
                  {
                    'urls': process.env.VUE_APP_STUN,
                    'username': process.env.VUE_APP_PEER_USER,
                    "iceTransportPolicy": 'relay',
                    'credential':process.env.VUE_APP_PEER_PWD,
                  }
                ]
              },
          });

        this.$peerInstance.on("open", (peer_id) => {
          this.$peerId = peer_id;
          this.current_peer_id = peer_id;

          let savePeerJsTokenDataForUser = {
               receiver_id: this.current_appointment_therapist.id,
               peer_id: peer_id,
               user_id: this.user.id,
               appointment_id: this.appointment.id,
               sender_name: this.user.name,
               provider_type: 'USER',
          };

          let callIntiatedData = {
               user_id: this.current_appointment_therapist.id,
               partner_id:(this.current_appointment_attendee ? this.current_appointment_attendee.id : null),
               videoDetails:{
                   sessionStarted:true,
                   appointment_id:this.appointment.id,
               }
          };

          this.$socket.emit('savePeerJsToken',savePeerJsTokenDataForUser);
          this.$socket.emit('callInitiated',callIntiatedData);
          if (this.current_appointment_attendee){
            let savePeerJsTokenDataForPartner = {
               receiver_id: this.current_appointment_attendee.id,
               peer_id: peer_id,
               user_id: this.user.id,
               appointment_id: this.appointment.id,
               sender_name: this.user.name,
               provider_type: 'USER',
            };
            this.$socket.emit('callInitiated',callIntiatedData);
            this.$socket.emit('savePeerJsToken',savePeerJsTokenDataForPartner);
          }

        });


        this.$peerInstance.on("connection", (connection) =>{
            console.log("peer connection establishment",connection);
        });
        
        this.$peerInstance.on("disconnected", (error) =>{
            console.log("peer disconnected by disconnect",error);
        });
        this.$peerInstance.on("error", (error) =>{
            console.log("peer disconnected by error",error.type);
        });
        this.$peerInstance.on("close", (error) =>{
            console.log("peer disconnected by close",error);
        });
        this.$peerInstance.on('call', (call) => {
                  call.answer(this.$mediaStream);
                  call.on('stream', (remoteMediaStream) => {
                      if(call.metadata.provider_type == 'THERAPIST'){
                       this.$mediaTherapistRemoteStream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: call.metadata.sender_name,
                              provider: call.metadata.provider_type,
                              blur:this.therapist_media_stream_status,
                            };
                        this.therapist_media_stream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: call.metadata.sender_name,
                              provider: call.metadata.provider_type,
                              blur:this.therapist_media_stream_status,
                            };
                      }else if(call.metadata.provider_type == 'PARTNER'){
                        this.$mediaPartnerRemoteStream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: call.metadata.sender_name,
                              provider: call.metadata.provider_type,
                              blur:this.partner_media_stream_status,
                            };
                        this.partner_media_stream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: call.metadata.sender_name,
                              provider: call.metadata.provider_type,
                              blur:this.partner_media_stream_status,
                            };
                      }
                  });
                  call.on('data', (remoteMediaStream) => {
                      console.log("data",remoteMediaStream);
                  });
                  call.on('disconnected', (info) => {
                    console.log("disconnected",call);
                    console.log("disconnected  info",info);
                  });
                  call.on('close', (info) => {
                    console.log("close",call);
                    console.log("close  info",info);
                  });
                  call.on('error', (info) => {
                     console.log("error",call);
                     console.log("error  info",info);
                  });
        });
      },
      callRemoteUser(remotePeerId, name, provider){
            let call_uniqid = this.generateUUID();
            let call = this.$peerInstance.call(remotePeerId,this.$mediaStream, {
                metadata: {
                  remotePeerId: call_uniqid,
                  sender_name: this.user.name,
                  provider_type: 'USER',
                  currentState: {
                    userBlurr: false,
                    partnerBlurr: false,
                    fullScreenBlurr: false,
                  }
                },
            });
            if(call){
              call.on('stream', (remoteMediaStream) => {
                    if(provider == 'THERAPIST'){
                       this.$mediaTherapistRemoteStream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: name,
                              provider:provider,
                              blur:this.therapist_media_stream_status,
                            };
                        this.therapist_media_stream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: name,
                              provider:provider,
                              blur:this.therapist_media_stream_status,
                            };
                      }else if(provider == 'PARTNER'){
                        this.$mediaPartnerRemoteStream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: name,
                              provider:provider,
                              blur:this.partner_media_stream_status,
                            };
                        this.partner_media_stream = {
                              remoteStream: remoteMediaStream,
                              peer_id: call.metadata.remotePeerId,
                              name: name,
                              provider:provider,
                              blur:this.partner_media_stream_status,
                            };
                      }
                    
                });
              call.on('disconnected', (error) => {
                   console.log("call user disconnected",error);
                 // this.removeVideoStream(call.metadata.remotePeerId);
              });
              call.on('close', (error) => {
                   console.log("call user close",error);
                  //this.removeVideoStream(call.metadata.remotePeerId);
              });
              call.on('error', (error) => {
                   console.log("call user error",error);
                  //this.removeVideoStream(call.metadata.remotePeerId);
              });

            }else{
               if(this.appointment){
                this.connectPeerjs();
               }
            }
      },
      generateUUID() { 
          var d = new Date().getTime();
          var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
          return 'xxxxxxxx-xxxx-8xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16;//random number between 0 and 16
              if(d > 0){//Use timestamp until depleted
                  r = (d + r)%16 | 0;
                  d = Math.floor(d/16);
              } else {//Use microseconds since page-load if supported
                  r = (d2 + r)%16 | 0;
                  d2 = Math.floor(d2/16);
              }
              return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
          });
      },
      appointmentVideoStatusUpdate(type){

        if(this.appointment){
           let save_session_video_status = Constant.getbaseurl()+'/therapist/appointment/video-status';
           let post_data = {'user_video_status': (type ? 'DISABLE' : 'ENABLE'),'appointment_id':this.appointment.id};
           this.axios.post(save_session_video_status,post_data)
           .then(() => {
                //this.extend_call_ask_status = 1;
           })
           .catch((error) => {
               if(error.response.status == 401){
                   Auth.logout();
                   this.$router.push('login');
               }else{
                  console.log("error",error);
               }
           });
        }               
      },
      appointmentStatusUpdate(data){
            this.current_appointment_status = data;
           
            if (this.appointment && this.current_appointment_status && (this.appointment_expired == 'PAYMENT_TIMEOUT') && (!this.current_appointment_status || (this.current_appointment_status == 'payment_error' || this.current_appointment_status == 'payment_status_error'))) {
                  this.modal_message = "Sorry "+this.user.name+", You takes to much time to make payment.";
                  this.show_message_modal = true;
                  this.current_appointment_status = null;
                  this.appointment = null;
                  this.search_data = null;
                  this.$router.push({'name':'home'});
            }

            if(this.appointment){
                if(this.current_appointment_status && (this.current_appointment_status == 'payment_done')){
                    this.current_session_status = "CALL_NOT_STARTED";
                    this.startCallByUser();
                    this.$router.push({'name':'call_page','params':{'appointment_id':this.appointment.id}});
                }
            }
      }
    },
    unmounted(){
    },
    mounted(){
      this.is_search = false;
      this.pause_call_time = false;
      this.show_make_card = false;
      this.show_message_modal = false;
      this.show_extend_message_modal = false;
      this.show_end_call_modal = false;
      this.show_end_call_early_modal = false;
      this.show_extend_confirmation_modal = false;
      this.accept_therapist_timeout = null;
      this.call_timer = 0;
      this.accept_therapist_timeout_status = false;
      this.extend_call_time = 0;
      this.current_call_extend_status = 0;
      this.client_call_interval_timeout = null;
      this.pause_call_timer = null;
      this.load_data = 0;
      this.series = 0;
      this.chat_messages = [];
      this.message_count = 0;
      this.extend_call_ask_status = 0;
      this.current_appointment_status = "payment_started";
      this.current_session_status = "CALL_STARTED";
      this.current_appointment_details = null;
      this.current_appointment_therapist = null;
      this.selected_therapist_profile = null;
      this.current_appointment_attendee = null;
      this.current_appointment_user = null;
      this.appointment_expired = null;
      this.current_user_video_stream = true;
      this.therapist_media_stream_status = false;
      this.partner_media_stream_status = false;
      this.media_stream = null;
      this.decline_reasons = null;
      this.therapist_media_stream = null;
      this.partner_media_stream = null;
      this.current_peer_id = null;
      this.show_payment = "add_card";
      this.connectSocket();
    },
    data(){
      return{
        web_key:process.env.VUE_APP_GOOGLE_WEB_KEYPAIR,
        load_data:0,
        series:0,
        chat_messages:[],
        pause_call_time:false,
        show_message_modal:false,
        is_search:false,
        show_extend_message_modal:false,
        show_end_call_modal:false,
        show_end_call_early_modal:false,
        show_extend_confirmation_modal:false,
        client_call_interval_timeout:null,
        call_timer:0,
        extend_call_ask_status:0,
        pause_call_timer:null,
        show_payment:"add_card",
        show_make_card:false,
        current_appointment_status:null,
        current_session_status:null,
        extend_call_time:0,
        current_call_extend_status:0,
        current_appointment_details:null,
        current_appointment_therapist:null,
        selected_therapist_profile:null,
        current_appointment_attendee:null,
        current_appointment_user:null,
        display_decline_reason_color:"none",
        message_count:0,
        display_chat_color:"none",
        show_reason_modal:"none",
        display_reason_color:"none",
        appointment:null,
        search_data:null,
        modal_message:null,
        appointment_expired:null,
        accept_therapist_timeout:null,
        accept_therapist_timeout_status:false,
        current_peer_id:null,
        therapist_media_stream_status:true,
        partner_media_stream_status:true,
        media_stream:null,
        decline_reasons:null,
        therapist_media_stream:null,
        partner_media_stream:null,
        media_stream_status:true,
        current_user_video_stream:true,
        is_mute:false,
        is_support:false,
        is_chat:false,
        is_blur:true,
        user : window.localStorage.getItem('naqqe_user') ? JSON.parse(window.localStorage.getItem('naqqe_user')) : null 
      }
    }
  }
</script>
<style scoped>
@import "assets/css/bootstrap.min.css";
@import "assets/css/owl.carousel.min.css";
@import "assets/css/style.css";
@import "assets/css/responsive.css";
@import "assets/css/animate.css";
@import "vue-select/dist/vue-select.css";
</style>

