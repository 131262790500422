import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs';
import VueSweetalert2 from 'vue-sweetalert2';
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueCreditCardValidation from 'vue-credit-card-validation';

const view_options = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
};
const firebaseConfig = {
  apiKey: process.env.VUE_APP_GOOGLE_APIKEY,
  authDomain: process.env.VUE_APP_GOOGLE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_GOOGLE_PROJECTID,
  storageBucket: process.env.VUE_APP_GOOGLE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_GOOGLE_MESSAGINGSENDERID,
  appId:process.env.VUE_APP_GOOGLE_APPID,
  measurementId: process.env.VUE_APP_GOOGLE_MEASUREMENTID
};
// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
const messaging = getMessaging(firebase_app);

// css files //
import "bootstrap";
import 'jquery/src/jquery.js';
import 'popper.js/dist/popper.min.js';
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap/dist/css/bootstrap.min.css"

const app = createApp(App)
app.config.globalProperties.$firebase_app = firebase_app;
app.config.globalProperties.$messaging = messaging;
app.config.globalProperties.$socket = null;
app.config.globalProperties.$peerInstance = null;
app.config.globalProperties.$peerId = null;
app.config.globalProperties.$mediaStream = null;
app.config.globalProperties.mediaTherapistRemoteStream = null;
app.config.globalProperties.mediaPartnerRemoteStream = null;
app.config.globalProperties.$isAvailable = 0;
app.use(router);
app.use(VueAxios, axios);
app.use(VueSweetalert2,view_options);
app.use(CKEditor);
app.use(VueCryptojs);
app.use(VueCreditCardValidation);
app.mount('#app')
