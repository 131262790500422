import axios from 'axios';

class Auth {

    constructor () {
        this.token = window.localStorage.getItem('naqqe_token');
        let userData =  window.localStorage.getItem('naqqe_user');
  //      console.log("user data",userData);
        if(userData != "undefined"){
        this.user = userData && userData !== undefined ? JSON.parse(userData) : null;
        }else{
        window.localStorage.removeItem('naqqe_user');
        window.localStorage.removeItem('naqqe_token');
        this.user = null;
        }
        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }
    login (token, user) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.token = token;
        this.user = user;
        window.localStorage.setItem('naqqe_token', token);
        window.localStorage.setItem('naqqe_user', JSON.stringify(user));
    }
    check(){
        if(this.token){
            let check_auth_token = axios.defaults.headers.common['Authorization'];
            if(!check_auth_token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;

            return this.token;
        }else{
            return null;
        }

    }
    logout () {
        // window.localStorage.clear();
        window.localStorage.removeItem('naqqe_user');
        window.localStorage.removeItem('naqqe_token');
        axios.defaults.headers.common['Authorization'] = null;
        this.user = null;
        this.token = null;
    }
    addHeader (key,value) {
        axios.defaults.headers.common[key] = value;
    }
}
export default new Auth();
